import React from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { NotFound } from './components/NotFound';
import { Page } from './components/Page';
import { GametypeState } from './components/context/GametypeState';


import './custom.css'
export const App = () => {

    return (        
             <GametypeState>
                    <Layout>
                        <Switch>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/om-oss' component={Page} />
                            <Route path='/:id/:row?' component={Home} />
                            <Route component={NotFound} />
                        </Switch>
                    </Layout>
            </GametypeState>         

    );
}
