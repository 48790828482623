import React from 'react';
import './Footer.css';

export const Footer = () => {
    //console.log('Render Footer Component');

    let d = new Date();
    let n = d.getFullYear();
    return (
        <footer className="footer text-white font-weight-bold">
            <div className="container">
                ©  {n} - Tipspro
            </div>
        </footer>
    )
}



