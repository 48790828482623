import React, { useState } from 'react';
import Matchtable from './Matchtable';
import RowArrayService from '../RowArrayService';
import './Gameplan.css';

export const Gameplan = ({ gametype, coupon, defaultrow }) => {

    const IsEightMatch = gametype === "stryktipset" || gametype === "europatipset" ? false : true;

    const intialFilter = IsEightMatch ?
        {
            ramradfilter: { onemin: 0, onemax: 8, drawmin: 0, drawmax: 8, twomin: 0, twomax: 8 },
            ramrad: RowArrayService.rowStringToArray(defaultrow, IsEightMatch),
            urad1: {},
            urad2: {},
            urad3: {},
            urad4: {},
            uradfilter: { urad1min: 0, urad1max: 8, urad2min: 0, urad2max: 8, urad3min: 0, urad3max: 8, urad4min: 0, urad4max: 8 }
        }
        : {
            ramradfilter: { onemin: 0, onemax: 13, drawmin: 0, drawmax: 13, twomin: 0, twomax: 13 },
            ramrad: RowArrayService.rowStringToArray(defaultrow, IsEightMatch),
            urad1: {},
            urad2: {},
            urad3: {},
            urad4: {},
            uradfilter: { urad1min: 0, urad1max: 13, urad2min: 0, urad2max: 13, urad3min: 0, urad3max: 13, urad4min: 0, urad4max: 13 }
        };

    const [filter, setFilter] = useState(intialFilter);
    const [counter, setCounter] = useState(defaultrow === "" ? -1 : RowArrayService.countRows(intialFilter.ramrad));
    const [uradcounter, setUradCounter] = useState([1,2]);

    const fillRamrad = (filledArray) => {
        setFilter({ ...filter, ramrad: filledArray });
    }
    const setRamrad = (e) => {
        let indexes = e.target.id.split('_'); //ramrad_0_1 = row1 has one checked
        const modifiedArray = RowArrayService.appendArray(filter.ramrad, indexes[1], e.target.value, e.target.checked);
        setFilter({ ...filter, ramrad: modifiedArray });

        if (RowArrayService.colArrayIsComplete(modifiedArray)) {
            //un-disable reloadbutton if uradfilters or rowfilter
            if (RowArrayService.hasUradFilterValues(filter.urad1, filter.urad2, filter.urad3, filter.urad4) ||
                RowArrayService.hasRowFilterValues(filter.ramradfilter, intialFilter.ramradfilter)) {
                //counter - 2 the rowcount has to be reloaded
                if (counter !== -2) {
                    setCounter(-2);
                }
            }
            else {
                const result = RowArrayService.countRows(modifiedArray)
                setCounter(result);
            }
        }
        else if (counter !== -1) {
            //counter has value or need to be recalculated and NOT colArrayIsComplete, restore counter to default i.e. -1
            setCounter(-1);
        }
    }
    const setUrad = (e) => {
        let indexes = e.target.id.split('_'); //urad1_0_0 = row1 has one checked
        switch (indexes[0]) {
            case 'urad1':
                let u1signs = filter.urad1[indexes[1]] ? filter.urad1[indexes[1]] : "";
                u1signs = u1signs.replace(e.target.value, '');
                if (e.target.checked) {
                    u1signs += e.target.value;
                }
                if (u1signs === '') {
                    const removeProp = indexes[1];
                    const { [removeProp]: remove, ...rest } = filter.urad1;
                    setFilter({ ...filter, urad1: rest });
                    if (!RowArrayService.hasUradFilterValues({}, filter.urad2, filter.urad3, filter.urad4) &&
                        !RowArrayService.hasRowFilterValues(filter.ramradfilter, intialFilter.ramradfilter)) {
                        const result = RowArrayService.countRows(filter.ramrad)
                        setCounter(result);
                        return;
                    }
                }
                else {
                    setFilter({ ...filter, urad1: { ...filter.urad1, [indexes[1]]: u1signs  } });

                }
                break;
            case 'urad2':
                let u2signs = filter.urad2[indexes[1]] ? filter.urad2[indexes[1]] : "";
                u2signs = u2signs.replace(e.target.value, '');
                if (e.target.checked) {
                    u2signs += e.target.value;
                }
                if (u2signs === '') {
                    const removeProp = indexes[1];
                    const { [removeProp]: remove, ...rest } = filter.urad2;
                    setFilter({ ...filter, urad2: rest });
                }
                else {
                    setFilter({ ...filter, urad2: { ...filter.urad2, [indexes[1]]: u2signs } });

                }
                break;
            case 'urad3':
                let u3signs = filter.urad3[indexes[1]] ? filter.urad3[indexes[1]] : "";
                u3signs = u3signs.replace(e.target.value, '');
                if (e.target.checked) {
                    u3signs += e.target.value;
                }
                if (u3signs === '') {
                    const removeProp = indexes[1];
                    const { [removeProp]: remove, ...rest } = filter.urad3;
                    setFilter({ ...filter, urad3: rest });
                }
                else {
                    setFilter({ ...filter, urad3: { ...filter.urad3, [indexes[1]]: u3signs } });

                }
                break;
            case 'urad4':
                let u4signs = filter.urad4[indexes[1]] ? filter.urad4[indexes[1]] : "";
                u4signs = u4signs.replace(e.target.value, '');
                if (e.target.checked) {
                    u4signs += e.target.value;
                }
                if (u4signs === '') {
                    const removeProp = indexes[1];
                    const { [removeProp]: remove, ...rest } = filter.urad4;
                    setFilter({ ...filter, urad4: rest });
                }
                else {
                    setFilter({ ...filter, urad4: { ...filter.urad4, [indexes[1]]: u4signs } });

                }
                break;
            default:
                throw new Error('Whoops! Something strange happend')
        }
        if (RowArrayService.colArrayIsComplete(filter.ramrad)) {
            //counter - 2 the rowcount has to be reloaded
            setCounter(-2);
        }
    }
    const setUradFilter = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, uradfilter: { ...filter.uradfilter, [name]: value} });
        //counter - 2 the rowcount has to be reloaded
        if (counter >= 0) {
            setCounter(-2);
        }
    }
    const setRamradFilter = (values) => {
        setFilter({ ...filter, ramradfilter: { ...filter.ramradfilter, ...values } });
    }
    const resetFilter = () => {
        setFilter(intialFilter);
    }
    const incrementUradCounter = (e) => {
        e.preventDefault();
        setUradCounter([...uradcounter, (uradcounter.length + 1)]);
    }

    return (
        <>
            <div className="card-body pb-0">
                <h1 className="card-title">{coupon.roundName}</h1>
                <div className="d-sm-flex flex-row mx-2">
                    <div className="coupon-info">
                        <span className="d-block d-sm-inline">{coupon.closeTimeString}</span>
                        <span className="d-none d-sm-inline"> |  </span>
                        <span className="d-block d-sm-inline">Omsättning: {coupon.turnOver}</span>
                    </div>
                    <button onClick={incrementUradCounter} className={`btn btn-primary text-nowrap ml-auto mb-1 float-right`} disabled={uradcounter.length === 4}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mr-1 bi bi-file-plus" viewBox="0 0 16 16">
                            <path d="M8.5 6a.5.5 0 0 0-1 0v1.5H6a.5.5 0 0 0 0 1h1.5V10a.5.5 0 0 0 1 0V8.5H10a.5.5 0 0 0 0-1H8.5V6z" />
                            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                        </svg>
                            U-rad
                    </button>
                </div>
                <form method="get" action="/">
                    <Matchtable gametype={gametype}
                        setUradFilter={setUradFilter}
                        counter={counter}
                        setCounter={setCounter}
                        matches={coupon.matches}
                        uradcounter={uradcounter}
                        filter={filter}
                        setRamrad={setRamrad}
                        setUrad={setUrad}
                        resetFilter={resetFilter}
                        fillRamrad={fillRamrad}
                        round={coupon.drawNumber}
                        roundName={coupon.roundName}
                        setRamradFilter={setRamradFilter}
                    />
                </form>
            </div>
        </>
    );
}