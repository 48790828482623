import React, { useState, useEffect } from 'react';

export const UradFilter = (props) => {

    const [uradlocalfilter, setUradlocalFilter] = useState(props.uradfilter);

    const UradFilterChange = (e) => {
          setUradlocalFilter({ ...uradlocalfilter, [e.target.name]: e.target.value });
        props.setUradFilter(e);
    };

    useEffect(() => {
        setUradlocalFilter(props.uradfilter);
    }, [props.uradfilter]);

        return (
            <>                
                <div className="form-group row m-0">
                    <label forhtml={`urad${props.index}min`} className="col-sm-5 col-form-label m-0 p-0 pt-2 label-small">Min:</label>
                    <div className="col-sm-7 p-0">
                        <input value={uradlocalfilter[`urad${props.index}min`]} onChange={UradFilterChange} min="0" max="13" id={`urad${props.index}min`} className="form-control pr-0" type="number" name={`urad${props.index}min`} />
                    </div>
                </div>
                <div className="form-group row m-0">
                    <label forhtml={`urad${props.index}max`} className="col-sm-5 col-form-label m-0 p-0 pt-2 label-small">Max:</label>
                    <div className="col-sm-7 p-0">
                        <input value={uradlocalfilter[`urad${props.index}max`]} onChange={UradFilterChange} min="1" max="13" id={`urad${props.index}max`} className="form-control pr-0" type="number" name={`urad${props.index}max`} />
                    </div>
                </div>
            </>
        );   
    
}
