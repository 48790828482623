import React from 'react';
import { useForm } from './hooks/useForm';
import RowGenerator from '../RowGenerator';

export const RowFilterModal = (props) => {

    const [values, handleChange] = useForm(props.filter.ramradfilter);

    const closeDialog = (e) =>{
        e.preventDefault();
        props.toggleRowFilterModal(e);
    }
    const save = (e) => {
        e.preventDefault();
        const noChanges = (props.filter.ramradfilter.onemin === values.onemin &&
            props.filter.ramradfilter.onemax === values.onemax &&
            props.filter.ramradfilter.drawmin === values.drawmin &&
            props.filter.ramradfilter.drawmax === values.drawmax &&
            props.filter.ramradfilter.twomin === values.twomin &&
            props.filter.ramradfilter.twomax === values.twomax);
        if (!noChanges) {            
            const rowgen = new RowGenerator(props.filter.ramrad, props.filter.urad1, props.filter.urad2, props.filter.urad3, props.filter.urad4, props.filter.uradfilter, values);
            const rows = rowgen.rowCount;
            props.setCounter(rows);            
            props.setRamradFilter(values);
        }
        props.toggleRowFilterModal(e);
    }


    return (
        <div className="modal modal-backdrop" style={{ display: 'block' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Reducera rader</h5>
                        <button onClick={closeDialog} type="button" className="close" data_dismiss="modal" aria_label="Stäng">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form method="post" action="/">
                            <div className="row form-group">
                                <label className="col-3 col-md-2 col-form-label">Ettor: </label>
                                <div className="col-3">
                                    <label forhtml="onemin" className="d-none">Ettor min</label>
                                    <input value={values.onemin} onChange={handleChange} min="0" max="13" id="onemin" className="form-control" type="number" name="onemin" placeholder="Min" />
                                </div>

                                <div className="col-3">
                                    <label forhtml="onemax" className="d-none">Ettor max</label>
                                    <input value={values.onemax} onChange={handleChange} min="1" max="13" id="onemax" className="form-control" type="number" name="onemax" placeholder="Max" />
                                </div>
                            </div>   
                            <div className="row form-group">
                                <label className="col-3 col-md-2 col-form-label">Kryss: </label>
                                <div className="col-3">
                                    <label forhtml="drawmin" className="d-none">Kryss min</label>
                                    <input value={values.drawmin} onChange={handleChange} min="0" max="13" id="drawmin" className="form-control" type="number" name="drawmin" placeholder="Min" />
                                </div>

                                <div className="col-3">
                                    <label forhtml="drawmax" className="d-none">Kryss max</label>
                                    <input value={values.drawmax} onChange={handleChange} min="1" max="13" id="drawmax" className="form-control" type="number" name="drawmax" placeholder="Max" />
                                </div>
                            </div>  
                            <div className="row form-group">
                                <label className="col-3 col-md-2 col-form-label">Tvåor: </label>
                                <div className="col-3">
                                    <label forhtml="twomin" className="d-none">Tvåor min</label>
                                    <input value={values.twomin} onChange={handleChange} min="0" max="13" id="twomin" className="form-control" type="number" name="twomin" placeholder="Min" />
                                </div>

                                <div className="col-3">
                                    <label forhtml="twomax" className="d-none">Tvåor max</label>
                                    <input value={values.twomax} onChange={handleChange} min="1" max="13" id="twomax" className="form-control" type="number" name="twomax" placeholder="Max" />
                                </div>
                            </div>  
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button onClick={save} type="button" className="btn btn-primary">Spara</button>
                        <button onClick={closeDialog} type="button" className="btn btn-secondary" data_dismiss="modal">Avbryt</button>
                    </div>
                </div>
            </div>
        </div>
    );
}