class RowArrayService {

 
    static appendArray(array, index, sign, checked) {
        if (checked) {
            if (sign === "1") {
                array[index].unshift(sign);
            }
            else if (sign === "2") {
                array[index].push(sign);
            }
            else {
                let xIndex = array[index].length === 0 ? 0 : 1;
                array[index].splice(xIndex, 0, "X");
            }
        }
        else {
            let signIndex = array[index].indexOf(sign)
            array[index].splice(signIndex, 1);
        }
        return array;
    }
    static fillIncompleteArray(array) {
        array.forEach((v) => {
            if (v.length === 0) {
                v.splice(0, 0, "1", "X", "2");
            }
        });
        return array;
    }

    static  colArrayIsComplete(array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].length === 0) {
                return false;
            }
        }
        return true;
    }

    static countRows(array) {
        let result = 1;
        array.forEach((v) => {
            result *= v.length;
        });
        return result;
    }
    static countRowsIncludeEmpty(array) {
        let result = 1;
        array.forEach((v) => {
            result *= (v.length === 0 ? 3 : v.length);
        });
        return result;
    }
    static hasUradFilterChanges(ramradFilter, changedRamradFilter) {
        for (const key in changedRamradFilter) {
            if (changedRamradFilter[key] !== ramradFilter[key]) {
                return true;
            }
        }
        return false;
    }
    static hasUradFilterValues(urad1, urad2, urad3, urad4) {
        return Object.keys(urad1).length > 0 || Object.keys(urad2).length > 0 || Object.keys(urad3).length > 0 || Object.keys(urad4).length > 0
    }


    static rowStringToArray(row, isEightMatch) {
        var rowArray =  isEightMatch ? [[], [], [], [], [], [], [], []] : [[], [], [], [], [], [], [], [], [], [], [], [], []];

        if (row === "") {
            return rowArray
        }
        let signsArr = row.split(',');
        for (var i = 0; i < signsArr.length; i++) {
            let signs = signsArr[i].toUpperCase();
            if (isEightMatch && i > 7 || !isEightMatch && i > 12) {
                break;
            }
            let signArr = signs.split('');
            rowArray[i] = signArr;
        }
        console.log(rowArray);
        return rowArray;
    }

    static hasRowFilterValues(rowfilter, initialrowfilter) {
        return !(rowfilter.onemin === initialrowfilter.onemin &&
            rowfilter.onemax === initialrowfilter.onemax &&
            rowfilter.drawmin === initialrowfilter.drawmin &&
            rowfilter.drawmax === initialrowfilter.drawmax &&
            rowfilter.twomin === initialrowfilter.twomin &&
            rowfilter.twomax === initialrowfilter.twomax);
    }
    static colArrayHasValues(array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].length > 0) {
                return true;
            }
        }
        return false;
    }

    
}
export default RowArrayService;