import React, { useEffect } from 'react';

export const RamradCheckbox = (props) => {

    const [checked, setChecked] = React.useState(false);

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        if (isChecked(props.index, props.sign)) {
            setChecked(true);
        }
    }, [props]);

    const isChecked = (index, sign) => {
        return props.ramrad[index].includes(sign) ? "checked" : null;
    }
    const onChange = (e) => {
        setChecked(!checked);
        props.setRamrad(e)
    }

    return (
        <React.Fragment>
            <input onChange={onChange} checked={checked} id={`${props.colname}_${props.index}_${props.sign}`} type="checkbox" className={props.classes} value={props.sign} />
            <label htmlFor={`${props.colname}_${props.index}_${props.sign}`}>
                <span>{props.sign}</span>
            </label>
         </React.Fragment>
        );


}