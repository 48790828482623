import React, { useContext, useEffect, useState } from 'react';
import { useApi, apiStates } from './hooks/useApi';
import { Gameplan } from './Gameplan';
import { Emptycoupon } from './EmptyCoupon';
import GametypeContext from './context/GametypeContext';


export const Coupon = (props) => {
    //console.log('Render Coupon Component for ' + props.gametype);

    const { state, error, data } = useApi(`api/round?type=${props.gametype}`, [props.gametype]);
    const [notfound, setNotFound ] = useState(false);
    const { gametype, setGametype } = useContext(GametypeContext);


    useEffect(() => {
        if (state === apiStates.SUCCESS) {
            if (data == null || data.matches == null) {
                if (typeof (props.route) === "undefined" && props.gametype === "stryktipset") {
                    setGametype("europatipset");
                }
                else if (typeof (props.route) === "undefined" && props.gametype === "europatipset") {
                    setGametype("topptipset");
                }
                else if (typeof (props.route) === "undefined" && props.gametype === "topptipset") {
                    setGametype("powerplay");
                }
                else {
                    setNotFound(true);
                }
            }
        }
    }, [state]);


    switch (state) {
        case apiStates.ERROR:
            return <div className="alert alert-danger text-center">ERROR: {error || 'General error'}</div>
        case apiStates.SUCCESS:
            if (data == null || data.matches == null) {
                return (<>
                    { notfound && <Emptycoupon gametype={props.gametype} />}
                </>);
            }
            return (    
                <>
                    <Gameplan gametype={props.gametype} coupon={data} defaultrow={props.defaultrow} />
                </>
            );
        default:
            return (
                <div className="text-center mt-4">
                    <div className="spinner-border" style={{ width: '5rem', height: '5rem' }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
    }   
}

