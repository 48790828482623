import React from 'react';
import RowArrayService from './../RowArrayService';

export const FillRamradModal = (props) => {

    const handleFillRamradModal = (e) => {
        e.preventDefault();
        if (e.target.innerText === "Ok") {
            let cloned = JSON.parse(JSON.stringify(props.ramrad));
            const modifiedArray = RowArrayService.fillIncompleteArray(cloned);
            const result = RowArrayService.countRows(modifiedArray)
            if (result < 100000) {
                props.fillRamrad(modifiedArray);
                props.setCounter(result);
            }
            return result;
        }
    }
    const closeDialog = (e) => {
        e.preventDefault()
        props.toggleFillRamradModal(0, "cancel");
    }
    const save = (e) => {
        var result = handleFillRamradModal(e);
        props.toggleFillRamradModal(result, "ok");
    }
    return (
        <div className="modal modal-backdrop" style={{ display: 'block' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Helgardera resten?</h5>
                        <button onClick={closeDialog} type="button" className="close" data_dismiss="modal" aria_label="Stäng">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>Du måste markera minst ett tecken för alla rader i din ursprungsrad för att kunna filtrera. Vill du  helgardera resten?</p>
                    </div>
                    <div className="modal-footer">
                        <button onClick={save} type="button" className="btn btn-primary">Ok</button>
                        <button onClick={closeDialog} type="button" className="btn btn-secondary" data_dismiss="modal">Avbryt</button>
                    </div>
                </div>
            </div>
        </div>
    );
}