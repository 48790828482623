import React, { useState} from 'react';

export const UradCheckbox = (props) => {

    const [checked, setChecked] = useState(false);
    const onChange = (e) => {
        setChecked(!checked);
        props.setUrad(e);
    }

    return (
        <React.Fragment>
            <input onChange={onChange} checked={checked} id={`urad${props.uradindex}_${props.index}_${props.sign}`} type="checkbox" className={props.classes} value={props.sign} />
            <label htmlFor={`urad${props.uradindex}_${props.index}_${props.sign}`}>
                <span>{props.sign}</span>
            </label>
         </React.Fragment>
        );


}