import React from 'react';
import { Link } from 'react-router-dom';

export const Emptycoupon = ({ gametype }) => {

    //console.log('Render Emptycoupon Component');

    return (
        <div className="card-body pb-0">
            <div className="text-center" style={{ height: '300px'} }>
                <h1 className="card-title">Åh, nej...</h1>
                <p>
                    Tyvärr kunde vi inte hämta nästa kupong för <span className="text-capitalize">{gametype}</span> ännu.
                    Det kan bero på att spelomgången inte är öppen.
                </p>
                <Link to="/stryktipset" className={`btn btn-primary btn-lg mt-2 mr-1 ${gametype === "stryktipset" ? " d-none" : ""}`}>Stryktipset</Link>
                <Link to="/europatipset" className={`btn btn-success btn-lg mt-2 mr-1${gametype === "europatipset" ? " d-none" : ""}`}>Europatipset</Link>
                <Link to="/topptipset" className={`btn btn-warning btn-lg mt-2 mr-1${gametype === "topptipset" ? " d-none" : ""}`}>Topptipset</Link>
                <Link to="/powerplay" className={`btn btn-danger btn-lg mt-2${gametype === "powerplay" ? " d-none" : ""}`}>Powerplay</Link>
            </div>
        </div>
        
    )
}



