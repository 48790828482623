import React, { useContext} from 'react';
import { Coupon } from './Coupon.js';
import { useParams } from "react-router-dom";
import GametypeContext from './context/GametypeContext';
import {SEO} from "./Seo";

export const Home = (props) => {

    let { id } = useParams();
    const params = new URLSearchParams(props.location.search);
    const row = params.get('row') ?? "";

    let title = "Reduceringsverktyg f\u00f6r dig som spelar systemspel | Tipspro";
    let description = 'Tipspro \u00e4r ett reduceringsverktyg f\u00f6r dig som spelar systemspel till spelformerna Stryktipset, Europatipset, Powerplay och Topptipset fr\u00e5n Svenska Spel.'
    switch (id) {
        case 'stryktipset':
            title = "Stryktipset reduceringsverktyg f\u00f6r systemspel | Tipspro";
            description = "Tipspro \u00e4r det enkla reduceringsverktyget f\u00f6r dig som ska spela p\u00e5 Svenska Spels Stryktipset och samtidigt spela lite smartare."
            break;
        case 'europatipset':
            title = "Europatipset reduceringsverktyg f\u00f6r systemspel | Tipspro";
            description = "Spela Europatipset med reducerade rader med Tipspro. Med v\u00e5rt enkla reduceringsverktyg kan du spela st\u00f6rre system till en l\u00e4gre kostnad."
            break;
        case 'topptipset':
            title = "Topptipset reduceringsverktyg f\u00f6r systemspel | Tipspro";
            description = "Reducera rader p\u00e5 Topptipset fr\u00e5n Svenska Spel med Tipspro. Vi erbjuder enkel reducering som \u00e4r enkel att anv\u00e4nda \u00e4ven f\u00f6r nyb\u00f6rjare."
            break;

        case 'powerplay':
            title = "Powerplay reduceringsverktyg f\u00f6r systemspel | Tipspro";
            description = "Spelar du Powerplay fr\u00e5n Svenska Spel? Med Tipspro kan du spela smartare med en mindre investering \u00e4n om du spelar ett matematiskt system"
            break;
    }

 
    const { gametype, setGametype } = useContext(GametypeContext);
    let gameTypeToLoad = "stryktipset";
    if (typeof (id) !== "undefined") {
        gameTypeToLoad = id;
        if (gametype !== gameTypeToLoad) {
            setGametype(gameTypeToLoad.toLowerCase())
        }
    }
    else {
        gameTypeToLoad = gametype;
    }
    return (
        <>
            <SEO title={title} description={description} />
             <div className="row">
            <div className="col-12 my-3">
                <div className="card" style={{ minHeight: '330px' }}>
                        <Coupon gametype={gameTypeToLoad} route={id} defaultrow={row} />                   
                </div>
            </div>
            </div>
        </>
    );  
}
