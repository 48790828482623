import React from 'react';

export const InfoModal = (props) => {

    const closeDialog = (e) =>{
        props.closeModal(e);
    }
    const save = (e) => {
        props.closeModal(e);
    }
    return (
        <div className="modal modal-backdrop" style={{ display: 'block' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{props.title}</h5>
                        <button onClick={closeDialog} type="button" className="close" data_dismiss="modal" aria_label="Stäng">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>{props.text}</p>
                    </div>
                    <div className="modal-footer">
                        <button onClick={save} type="button" className="btn btn-primary">Ok</button>
                        <button onClick={closeDialog} type="button" className="btn btn-secondary" data_dismiss="modal">Avbryt</button>
                    </div>
                </div>
            </div>
        </div>
    );
}