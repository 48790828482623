import React from 'react';
import { Footer } from './Footer.js';
import { NavMenu } from './NavMenu';

export const Layout = (props) =>  {
    return(
      <div>
        <NavMenu />
        <div className="container px-1-sm">
          {props.children}
        </div>
        <Footer/>
      </div>
    );
  
}
