import React from 'react';
import { RamradCheckbox } from './RamradCheckbox';
import { UradCheckbox } from './UradCheckbox';
import { UradFilter } from './UradFilter';
import FilterSettings from './FilterSettings';
import './Matchtable.css';

const Matchtable = (props) => {

    //console.log('Rendering MatchTable component');

    const renderUrader = (match) => {
        return (
            <>
                {props.uradcounter.map(
                    (n, index) => (
                        <td className={`text-center roundprediction${n > 1 ? " border-left" : ""}`} key={index}>
                            <div className="d-flex flex-row w-90p">
                                <div className="col-4">
                                    <UradCheckbox uradindex={n}
                                        index={match.eventNumber - 1}
                                        setUrad={props.setUrad}
                                        sign="1"
                                        classes="d-none" />
                                </div>
                                <div className="col-4">
                                    <UradCheckbox uradindex={n}
                                        index={match.eventNumber - 1}
                                        setUrad={props.setUrad}
                                        sign="X"
                                        classes="d-none" />
                                </div>
                                <div className="col-4">
                                    <UradCheckbox uradindex={n}
                                        index={match.eventNumber - 1}
                                        setUrad={props.setUrad}
                                        sign="2"
                                        classes="d-none" />
                                </div>
                            </div>
                        </td >
                    ),
                )}
            </>
        );
    }
    const renderUradHeading = () => {
        return (
            <>
                {props.uradcounter.map(
                    (n, index) => (
                        <React.Fragment key={index}>
                            <th className="text-center w-100p">Urad-{n}</th>
                        </React.Fragment>
                    ),
                )}
            </>
        );
    }
    const renderUradFilter = () => {
        return (
            <>
                {props.uradcounter.map(
                    (n, index) => (
                        <td key={index}>
                            <UradFilter index={n} uradfilter={props.filter.uradfilter} setUradFilter={props.setUradFilter} />
                        </td>
                    ),
                )}
            </>
        );
    }

    return (
        <div className={`table-responsive-lg${props.uradcounter.length > 2 ? " rtl" : ""}`}>
            <table className={`table table-sm table-hover table-coupon${props.uradcounter.length > 2 ? " ltr" : ""}`}>
                    <thead>
                        <tr>
                            <th className="text-center w-25p">#</th>
                            <th>Match</th>
                            <th className="text-center">Start</th>
                            <th className="text-center w-90p">Procent</th>
                            <th className="text-center w-90p d-none d-md-table-cell">Odds</th>
                            <th className="text-center w-100p">1 &nbsp; X &nbsp; 2</th>
                        {renderUradHeading()}
                        </tr>
                    </thead>
                    <tbody>
                    {props.matches.map(match =>
                        <tr key={match.eventNumber}>
                            <td className="text-center">{match.eventNumber}</td>
                            <td>
                                {match.teamsDescription == '' ?
                                    <>
                                        <span className="d-none d-sm-inline-block">{match.home}</span>
                                        <span className="d-block d-sm-none text-nowrap">{match.homeShort}</span>
                                        <span className="d-none d-sm-inline-block"> - </span>
                                        <span className="d-none d-sm-inline-block">{match.away}</span>
                                        <span className="d-block d-sm-none text-nowrap">{match.awayShort}</span>
                                    </>
                                    :
                                    <span>{match.teamsDescription}</span>
                                }
                            </td>
                            <td className="text-center">{match.matchStartString}</td>
                            <td className="percentinfo text-nowrap text-center">
                                <span>{match.odds.home}</span>-
                                <span>{match.odds.draw}</span>-
                                <span>{match.odds.away}</span>
                            </td>
                            <td className="percentinfo d-none d-md-table-cell text-nowrap text-center">
                                <span>{match.distribution.homePercent}</span>-
                                <span>{match.distribution.drawPercent}</span>-
                                <span>{match.distribution.awayPercent}</span>
                            </td>
                            <td className="text-center roundprediction border-rightleft-dark bg-light">
                                <div className="d-flex flex-row w-90p">
                                    <div className="col-4">
                                        <RamradCheckbox colname="ramrad"
                                            ramrad={props.filter.ramrad}
                                            setRamrad={props.setRamrad}
                                            index={match.eventNumber - 1}
                                            sign="1"
                                            classes="d-none" />
                                    </div>
                                    <div className="col-4">
                                        <RamradCheckbox colname="ramrad"
                                            ramrad={props.filter.ramrad}
                                            setRamrad={props.setRamrad}
                                            index={match.eventNumber - 1}
                                            sign="X"
                                            classes="d-none" />
                                    </div>
                                    <div className="col-4">
                                        <RamradCheckbox colname="ramrad"
                                            ramrad={props.filter.ramrad}
                                            setRamrad={props.setRamrad}
                                            index={match.eventNumber - 1}
                                            sign="2"
                                            classes="d-none" />
                                    </div>
                                </div>
                            </td>
                            {renderUrader(match)}
                        </tr>
                    )} 
                    {props.uradcounter.length > 0 ?
                        <tr className="bg-light border border-bottom">
                            <td colSpan="5" className="pt-2">
                                <FilterSettings gametype={props.gametype}
                                    resetFilter={props.resetFilter}
                                    counter={props.counter}
                                    setCounter={props.setCounter}
                                    filter={props.filter}
                                    setRamradFilter={props.setRamradFilter}
                                    fillRamrad={props.fillRamrad}
                                    round={props.round}
                                    roundName={props.roundName}
                                />
                            </td>
                            <td className="d-none d-md-table-cell"></td>
                            {renderUradFilter()}
                        </tr>
                        :null
                    }
                </tbody>
            </table>
        </div>
        );   

}

export default React.memo(Matchtable);
//export default React.memo(Matchtable, (prevProps, nextProps) => {
//    return (prevProps.uradcounter === nextProps.uradcounter && prevProps.gametype === nextProps.gametype);
//});
