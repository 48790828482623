import React, { useEffect } from 'react';

export const apiStates = {
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

export const useApi = (url, dependencies) => {
    const [data, setData] = React.useState({
        state: apiStates.LOADING,
        error: '',
        data: [],
    });

    const setPartData = (partialData) => setData({ ...data, ...partialData });

    useEffect(() => {
        //console.log('sending httprequest');
        setPartData({
            state: apiStates.LOADING,
        });
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setPartData({
                    state: apiStates.SUCCESS,
                    data
                });
            })
            .catch(() => {
                setPartData({
                    state: apiStates.ERROR,
                    error: 'Fel när data skulle hämtas från api'
                });
            });
    }, dependencies, url);

    return data;
};