import React from 'react';
import { Link } from 'react-router-dom';


export const NotFound = () => {


    return (
        <div className="container text-center">
            <h1>Sidan kunde inte hittas :(</h1>
            <p>404 - Vi kunde hitta sidan du letar efter. Leta på startsidan för att hitta det du söker.</p>
            <Link to="/" className="btn btn-primary btn-lg mt-2">Gå till startsidan</Link>
    </div>);


}