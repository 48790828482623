import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './Filtersettings.css';
import { RowFilterModal } from './RowFilterModal';
import { FillRamradModal } from './FillRamradModal';
import { InfoModal } from './InfoModal';
import RowGenerator from '../RowGenerator';
import RowArrayService from './../RowArrayService';
//import { saveAs } from 'file-saver';

const FilterSettings = (props) => {

    //console.log('Loading component fitersettings, gametype ' + props.gametype)

    const [maxrowmodalopen, setMaxRowModalOpen] = useState(false);
    const [rowfiltermodalopen, setRowFilterModalOpen] = useState(false);
    const [fillramradmodalopen, setFillRamradModalOpen] = useState(false);
    const [disablesave, setDisableSave] = useState(false);
    const [gametypedropdownopen, setGameTypeDropdownOpen] = useState(false);

    const toggleRowFilterModal = (e) => {
        e.preventDefault();
        //counter = -1, ramrad is incomplete
        if (!rowfiltermodalopen && props.counter === -1) {
            setFillRamradModalOpen(true);
            return;
        }
        if (!rowfiltermodalopen && props.counter > 100000) {
            setMaxRowModalOpen(true);
            return;
        }
        setRowFilterModalOpen(!rowfiltermodalopen);
    }
    const toggleFillRamradModal = (result, action) => {
        if (action === "ok") {
            result > 100000 ? setMaxRowModalOpen(true) : setRowFilterModalOpen(!rowfiltermodalopen);
        }
        setFillRamradModalOpen(!fillramradmodalopen);
    }
    const handleMaxRowModal = (e) => {
        e.preventDefault();
        setDisableSave(false);
        setMaxRowModalOpen(!maxrowmodalopen);
    }
    const exportToFile = (e) => {
        e.preventDefault();
        //preventDoubleclick
        setDisableSave(true);

        const ramradCount = RowArrayService.countRows(props.filter.ramrad);
        //check if rows > 100000 = popup
        if (ramradCount > 10000) {
            setMaxRowModalOpen(!maxrowmodalopen);
            return;
        }
        let rowgen = new RowGenerator(props.filter.ramrad, props.filter.urad1, props.filter.urad2, props.filter.urad3, props.filter.urad4, props.filter.uradfilter, props.filter.ramradfilter);
        let data = {
            "drawNumber": props.round,
            "system": "single",
            "betRowAmount": 1,  
            "items": rowgen.exportFile,
            "client": "Tipspro 1.0",
            "retailer": "Tipspro"
        }
        //console.log(JSON.stringify(data));
        let roundName = props.gametype;
        if (props.roundName.toLowerCase() === "topptipset extra") {
            roundName = "topptipseteuropa"
        }
        else if (props.roundName.toLowerCase() === "topptipset stryk") {
            roundName = "topptipsetstryk"
        }

        fetch('/api/svs?gametype=' + roundName, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        }).then(response => response.json())
            .then((json) => {
                //console.log(json);
                if (json.error != '' && json.resonse?.url != '') {
                    //alert(json.response.url);
                    window.location.href = json.response.url;
                }
            }).catch (err => alert("Filen kunde inte skickas till Svenska spel. Kontrollera att omg\u00e5ngen är öppen för spel."));


        //export tofile
        //const exportfilename = props.gametype.charAt(0).toUpperCase() + this.slice(1);
        //const fileContent = (exportfilename +'\r\n') + rowgen.exportFile.join('');
        //const blob = new Blob([fileContent], { type: "text/plain;charset=utf-8" });
        //saveAs(blob, props.gametype);

        setDisableSave(false);
    }
    const updateCounter = (e) => {
        e.preventDefault();

        const ramradCount = RowArrayService.countRows(props.filter.ramrad);
        //check if rows > 100000 = popup
        if (ramradCount > 100000) {
            setMaxRowModalOpen(!maxrowmodalopen);
        }
        else {
            const rowgen = new RowGenerator(props.filter.ramrad, props.filter.urad1, props.filter.urad2, props.filter.urad3, props.filter.urad4, props.filter.uradfilter, props.filter.ramradfilter);
            const rows = rowgen.rowCount;
            props.setCounter(rows);
        }

    }
    const toggleGametype = (e) => {
        if (props.gametype !== e.target.innerText.toLowerCase()) {
            props.resetFilter();
        }
        else {
            e.preventDefault();
        }
    }
    const toggleGametypeDropdown = (e) => {
        e.preventDefault();
        setGameTypeDropdownOpen(!gametypedropdownopen);
        
    }

    return (
        <div className="row mt-1">
            <div className="col-12">
                <div className="row">
                    <div className="ccol-12 col-lg-6">
                        <p className="mb-0 gamelabel">Välj spelform:</p>
                        <div className="dropdown mb-2">
                            <a onClick={toggleGametypeDropdown} className="bg-white btn border border-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data_toggle="dropdown" aria_haspopup="true" aria_expanded={gametypedropdownopen.toString()}>
                                <span className="w-200p d-inline-block text-left">{props.gametype.charAt(0).toUpperCase() + props.gametype.slice(1)}</span>
                            </a>
                            <div className={`dropdown-menu${gametypedropdownopen ? " dropdown-show" : ""}`} aria-labelledby="dropdownMenuLink">
                                <Link onClick={toggleGametype} to="/stryktipset" className={`dropdown-item text-nowrap${props.gametype === "stryktipset" ? " active" : ""}`}>Stryktipset</Link>
                                <Link onClick={toggleGametype} to="/europatipset" className={`dropdown-item text-nowrap${props.gametype === "europatipset" ? " active" : ""}`}>Europatipset</Link>
                                <Link onClick={toggleGametype} to="/topptipset" className={`dropdown-item text-nowrap${props.gametype === "topptipset" ? " active" : ""}`}>Topptipset</Link>
                                <Link onClick={toggleGametype} to="/powerplay" className={`dropdown-item text-nowrap${props.gametype === "powerplay" ? " active" : ""}`}>Powerplay</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="input-group mb-2 w-200p mx-left mx-lg-auto">
                            <div className="form-control text-center text-center p-0 border-secondary">
                                <div>
                                    <span className="h3 m-0 text-bold">{props.counter > 100000 ? "100000+" : (props.counter === -2 ? "?" : props.counter === -1 ? "0" : props.counter)}</span>
                                    <span className="text-uppercase text-bottom"> Rader</span>
                                </div>
                            </div>
                            <div className="input-group-append">
                                <button onClick={updateCounter} type="button" className="btn btn-sm btn-dark" disabled={props.counter !== -2}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-repeat" viewBox="0 0 16 16">
                                        <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"></path>
                                        <path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"></path>
                                    </svg>
                                    <span className="visually-hidden">Reload</span>
                                </button>
                            </div>
                        </div>
                        <div className="text-left text-lg-center">
                            <button onClick={toggleRowFilterModal} className={`btn btn-primary text-nowrap mr-1`}>Reducera rader</button>
                            <button onClick={exportToFile} className={`btn btn-success text-nowrap`} disabled={disablesave || props.counter <= 0}>Lämna in</button>
                        </div>

                    </div>
                </div>
            </div>
            {rowfiltermodalopen && <RowFilterModal
                toggleRowFilterModal={toggleRowFilterModal}
                filter={props.filter}
                setCounter={props.setCounter}
                setRamradFilter={props.setRamradFilter}
            />}
            {fillramradmodalopen && <FillRamradModal
                toggleFillRamradModal={toggleFillRamradModal}
                fillRamrad={props.fillRamrad}
                ramrad={props.filter.ramrad}
                setCounter={props.setCounter} />}
            {maxrowmodalopen && <InfoModal closeModal={handleMaxRowModal} title="Maxgränsen överskriden" text={`${disablesave ? "Du kan exportera max 10000 rader." : "Du kan max ha 100000 rader i din ursprungsrad för att kunna filtrera."}`} />}
        </div>
    );
}



export default React.memo(FilterSettings);