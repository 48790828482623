import React, { useState } from 'react';
import GametypeContext from './GametypeContext';


export const GametypeState = ({ children }) => {

    //const isStryktipsDay = new Date().getDay() > 2 && new Date().getDay() !== 0;
    //const [gametype, setGametype] = useState(isStryktipsDay ? "stryktipset" : "europatipset");
    const [gametype, setGametype] = useState("stryktipset");

    return (
        <GametypeContext.Provider value={
            {
                gametype: gametype,
                setGametype: setGametype
            }
        }>
            {children}
        </GametypeContext.Provider>
    );
}