class RowGenerator {

    constructor(ramrad, urad1, urad2, urad3, urad4, uradfilter, rowfilter) {
        this.ramrad = ramrad;
        this.urad1 = urad1;
        this.urad2 = urad2;
        this.urad3 = urad3;
        this.urad4 = urad4;
        this.uradfilter = uradfilter;
        this.rowfilter = rowfilter
    }

    get hasUrader() {
        return Object.keys(this.urad1).length > 0 || Object.keys(this.urad2).length > 0 || Object.keys(this.urad3).length > 0 || Object.keys(this.urad4).length > 0
    }   

    rowMatchUrad(row, urad, min, max) {
        if (Object.keys(urad).length > 0) {
            let matches = 0;
            for (const key in urad) {
                let sign = row.charAt(key);
                if (urad[key].indexOf(sign) > -1) {
                    matches++;
                }
            }
            if (matches >= min && matches <= max) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    }

    get exportFile() {
        let rows = this.rows;
        for (var i = 0; i < rows.length; i++) {
            rows[i] = rows[i].replace(/([1,X,2]{1}(?!,|$))/g, "$1,");
        }
        return rows;

    }

    get rowCount() {
        return this.rows.length;
    }
    get rows() {
        //remove empty arrays
        let baseRows = this.getCombn(this.ramrad.filter((el) => el.length > 0)); //1111111111111
        let filteredRows = [];
        let hasUrad1 = Object.keys(this.urad1).length > 0;
        let hasUrad2 = Object.keys(this.urad2).length > 0;
        let hasUrad3 = Object.keys(this.urad3).length > 0;
        let hasUrad4 = Object.keys(this.urad4).length > 0;
       
        for (var i = 0; i < baseRows.length; i++) {
            let nrOne = baseRows[i].split('1').length-1;
            let nrDraw = baseRows[i].split('X').length-1;
            let nrTwo = baseRows[i].split('2').length-1;
            //filter on signs
            if (nrOne < this.rowfilter.onemin || nrOne > this.rowfilter.onemax) {
                continue;
            } if (nrDraw < this.rowfilter.drawmin || nrDraw > this.rowfilter.drawmax) {
                continue;
            }
            if (nrTwo < this.rowfilter.twomin || nrTwo > this.rowfilter.twomax) {
                continue;
            }
            if (hasUrad1 && !this.rowMatchUrad(baseRows[i], this.urad1, this.uradfilter.urad1min, this.uradfilter.urad1max)) {
                continue;
            }    
            if (hasUrad2 && !this.rowMatchUrad(baseRows[i], this.urad2, this.uradfilter.urad2min, this.uradfilter.urad2max)) {
                continue;
            }     
            if (hasUrad3 && !this.rowMatchUrad(baseRows[i], this.urad3, this.uradfilter.urad3min, this.uradfilter.urad3max)) {
                continue;
            }     
            if (hasUrad4 && !this.rowMatchUrad(baseRows[i], this.urad4, this.uradfilter.urad4min, this.uradfilter.urad4max)) {
                continue;
            }     
              
            filteredRows.push(baseRows[i]);
        }
        return filteredRows;
    }
    colArrayHasValues(array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].length > 0) {
                return true;
            }
        }
        return false;
    }
    getCombn(arr, pre) {
        let self = this;
        pre = pre || '';
        if (!arr.length) {
            return pre;
        }
        var ans = arr[0].reduce(function (ans, value) {
            return ans.concat(self.getCombn(arr.slice(1), pre + value));
        }, []);
        return ans;
    }
}
export default RowGenerator;