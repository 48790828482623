import React, { useEffect } from 'react';
import { SEO } from "./Seo";

export const Page = () => {
    useEffect(() => {
        document.body.className = 'page';
        return () => { document.body.className = ''; }
    });
    return (
        <>
            <SEO title="Om Tipspro systemspel | Tipspro" description="Tipspro \u00e4r ett reduceringsverktyg som \u00e4r framtaget av Eastandsthlm AB. Vi \u00e4lskar streckspel fr\u00e5n Svenska Spel och utvecklar hela tiden nya produkter." />
            <div className="bg-white container p-4">     
                <h1>Om Webbplatsen</h1>
                <p>Tipspro &auml;gs och drivs av Eastandsthlm AB och &auml;r en webbplats som erbjuder systemspel till spelformerna Styktipset, Europatipset, Topptipset och Powerplay fr&aring;n Svenska Spel Sport & Casino.</p>
                <p>P&aring; Tipspro beh&ouml;ver du inte logga in eller registrera dig utan det &auml;r fritt fram f&ouml;r alla som vill att kostnadsfritt anv&auml;nda v&aring;rt reduceringsverktyg. G&aring; vidare till 
                   &nbsp;<a href="/">Systemspel</a></p>
            </div>
        </>
    );
}
